import useFetch from '../api/useFetch';
import { useParams, Link } from 'react-router-dom';
import Loading from './Loading';
import '../css/main.css';


const Game = () => {
    const { id } = useParams();
    // const [season, setSeason] = useState("2023")
    const { result: game, isLoading: gameLoading } = useFetch(`/game/${id}`,[]);
  const gameStats = ['Shots on Goal','Shots off Goal','Total Shots','Blocked Shots','Shots insidebox','Shots outsidebox','Fouls','Corner Kicks','Offsides','Ball Possession','Yellow Cards','Red Cards','Goalkeeper Saves','Total passes','Passes accurate','Passes %','expected_goals']

  return (
   <>
            {gameLoading ? (
                <Loading />
            ) : game && (
              <>
              <div className="content game">
                <div className="game-header">
                  <div className={`header ${game.status === "Match Finished"  && "large"}`}>
                    <Link to={`/team/${game.home_team.id}`}><img className="header-logo" src={game.home_team.logo_url} alt={game.home_team.name} /></Link>
                    <div className="goal">{game.goals_home_et + game.goals_home_ft || game.goals_home_ht}</div>
                  </div>
                  <div className="header large">
                  <Link to={`/league/${game.league.api_id}`}><img height="70" width="70" className="header-logo" src={game.league.logo_url} alt={game.league.name} /></Link>
                  <center><small>{game.league.name}</small></center>
                  {game.status === "Not Started"  && <>
                      <center><b>{game.status}</b></center>
                      <center><b>{game.date}</b></center>
                      <center><b>{game.time}</b></center>
                      <center><b>{game.win_prediction}</b></center></>}
                  {game.status === "Match Finished"  && <>
                      <center><b>{game.date}</b></center>
                      <center><b>{game.status}</b></center>
                      <center><b>({game.goals_home_ht} - {game.goals_away_ht})</b></center></>}
                  </div>
                  <div className={`header ${game.status === "Match Finished"  && "large"}`}>
                    <Link to={`/team/${game.away_team.id}`}><img className="header-logo" src={game.away_team.logo_url} alt={game.away_team.name} /></Link>
                    <div className="goal">{game.goals_away_et + game.goals_away_ft || game.goals_away_ht}</div>
                  </div>
                  </div>
                  <div className="lineups">
                    <div className="lineup">
                      <center><b>{game.home_team.name.toUpperCase()}</b></center>
                      <br />
                      <div>
                      {game.lineups.map((item) => (item.team.id === game.home_team.id && item.starter && <div>
                          <span className='pos'>{item.player.position}</span><Link to={`/player/${item.player.id}`}>{item.team.id === game.home_team.id && item.starter && item.player.name}</Link>
                          {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.comments !== "Penalty Shootout" && event.sub_type !== "Missed Penalty" && <i className={`fas fa-futbol ${event.sub_type === "Own Goal" && 'red'}`}></i>))}
                          {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && <i class="fas fa-hand-holding"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type === "Missed Penalty" && event.comments !== "Penalty Shootout" && <i class="fas fa-futbol yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Yellow Card" && <i class="fas fa-square yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Red Card" && <i class="fas fa-square red"></i>))}
                        {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "subst" && <i class="fas fa-arrow-circle-down red"></i>))}
                          </div>))}
                        <br />
                        <div class="coach"><span className='pos coach'>C</span><Link to={game.home_coach && `/coach/${game.home_coach.id}`}>{game.home_coach && game.home_coach.name}</Link></div>
                        <br />
                        {game.lineups.map((item) => (item.team.id === game.home_team.id && item.substitute && <div>
                          <span className='pos'>{item.player.position}</span><Link to={`/player/${item.player.id}`}>{item.team.id === game.home_team.id && item.substitute && item.player.name}</Link>
                          {game.events.map((event) => (event.player === item.player.id && event.general_type === "subst" && <i class="fas fa-arrow-circle-up green"></i>))}
                        {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && <i class="fas fa-hand-holding"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && event.comments !== "Penalty Shootout" && <i className={`fas fa-futbol ${event.sub_type === "Own Goal" && 'red'}`}></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type === "Missed Penalty" && event.comments !== "Penalty Shootout" && <i class="fas fa-futbol yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Yellow Card" && <i class="fas fa-square yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Red Card" && <i class="fas fa-square red"></i>))}                      </div>))}
                          </div>
                    </div>
                    {game.game_stats.length > 0 && (
                    <div className="game-stats">
                      <i class="fas fa-stream"></i>
                      {gameStats.map((item) => (<div>
                        {game.game_stats.map((stat) => stat.label === item && stat.team === game.home_team.id && (stat.value || 0) )}
                        <span className='game-stat'>{item}</span>
                        {game.game_stats.map((stat) => stat.label === item && stat.team === game.away_team.id && (stat.value || 0))}
                      </div>))}
                    </div>)}
                    <div className="lineup">
                      <center><b>{game.away_team.name.toUpperCase()}</b></center>
                      <br />
                      <div>
                      {game.lineups.map((item) => (item.team.id === game.away_team.id && item.starter && <div>
                        <span className='pos'>{item.player.position}</span><Link to={`/player/${item.player.id}`}>{item.team.id === game.away_team.id && item.starter && item.player.name}</Link>
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && event.comments !== "Penalty Shootout" && <i className={`fas fa-futbol ${event.sub_type === "Own Goal" && 'red'}`}></i>))}
                        {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && <i className="fas fa-hand-holding"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type === "Missed Penalty" && event.comments !== "Penalty Shootout" && <i className="fas fa-futbol yellow"></i>))}





                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Yellow Card" && <i class="fas fa-square yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Red Card" && <i class="fas fa-square red"></i>))}
                        {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "subst" && <i class="fas fa-arrow-circle-down red"></i>))}
                      </div>))}
                      <br />
                      <div class="coach"><span className='pos coach'>C</span><Link to={game.away_coach && `/coach/${game.away_coach.id}`}>{game.away_coach && game.away_coach.name}</Link></div>
                      <br />
                      {game.lineups.map((item) => (item.team.id === game.away_team.id && item.substitute && <div>
                        <span className='pos'>{item.player.position}</span><Link to={`/player/${item.player.id}`}>{item.team.id === game.away_team.id && item.substitute && item.player.name}</Link>
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "subst" && <i class="fas fa-arrow-circle-up green"></i>))}
                        {game.events.map((event) => (event.assist_player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && <i class="fas fa-hand-holding"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type !== "Missed Penalty" && event.comments !== "Penalty Shootout" && <i className={`fas fa-futbol ${event.sub_type === "Own Goal" && 'red'}`}></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.general_type === "Goal" && event.sub_type === "Missed Penalty" && event.comments !== "Penalty Shootout" && <i className="fas fa-futbol yellow"></i>))}
                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Yellow Card" && <i class="fas fa-square yellow"></i>))}

                        {game.events.map((event) => (event.player === item.player.id && event.sub_type === "Red Card" && <i class="fas fa-square red"></i>))}
                      </div>))}
                      </div>
                    </div>
                  </div>
              </div>
              </>
              )
            }
    </>
  )
}

export default Game;