import useFetch from '../api/useFetch';
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../css/main.css';
import Loading from './Loading';
import Table from './Table';


const Team = () => {
    const { id } = useParams();
    const [tab, setTab] = useState("Fixtures")
    const [homeAway, setHomeAway] = useState(null)
    const [goalsPerStarterSort, setGoalsPerStarterSort] = useState(null);
    const [gamesTableContent, setGamesTableContent] = useState(null);

    const { result: team, isLoading: teamLoading, error: teamError} = useFetch(`/team/${id}`,[]);

      const totalGames = () => {
        return team.games.filter(game => game.has_lineups).length
      }

      const statProcess = (player, stat_name) => {
        return player.player_stats.filter(stat => stat.stat === stat_name && stat.team === team.id).reduce((sum, stat) => sum + parseInt(stat.value), 0)
      }

      const gamesTableHeader = [
        { content: "Competition", size: "md", isLink: true, link: 'league', sort: 'normal' },
        { content: "Time", size: "md", isLink: false, sort: 'normal'  },
        { content: "Home", size: "md", isLink: true, link: 'team', sort: 'normal' },
        { content: "Away", size: "md", isLink: true, link: 'team', sort: 'normal' },
        { content: "Lineups", size: "sm", isLink: false, sort: 'normal' },
        { content: "Status", size: "md", isLink: false, sort: 'normal' },
        { content: "Score", size: "sm", isLink: true, link: 'game', sort: 'normal' },
        { content: "Xwin", size: "sm", isLink: false, sort: 'abs' },
        { content: "Xgoals", size: "sm", isLink: false, sort: 'normal' },
        { content: "XBTTS", size: "sm", isLink: false, sort: 'normal' },
        { content: "UNXF", size: "sm", isLink: false, sort: 'normal' }
      ];

    useEffect(() => {
        if (team !== null) {
          setGamesTableContent(
            team.games.map((game) => 
                [[game.league.name,game.league.api_id],
                game.time,
                [game.home_team.name,game.home_team.id],
                [game.away_team.name,game.away_team.id],
                game.has_lineups,
                game.status,
                [game.status === "Match Finished" ? `${game.goals_home_et + game.goals_home_ft} - ${game.goals_away_et + game.goals_away_ft}` : ' --- ',game.id],
                game.win_prediction,
                game.goals_nb_prediction_poisson,
                game.bts_prediction,
                game.unexpected_factor
              ]
          ));
        }
      }, [team]);


const handleSort = (column) => {
    if (column === "goalsPerStarter") {
        const sortedArray = [...team.current_players].sort((a, b) => {
            const goalsPerStarterA = statProcess(a, "Starter") === 0 ? 0 : statProcess(a, "Goals") / statProcess(a, "Starter");
            const goalsPerStarterB = statProcess(b, "Starter") === 0 ? 0 : statProcess(b, "Goals") / statProcess(b, "Starter");
            return goalsPerStarterSort === "asc"
                ? goalsPerStarterA - goalsPerStarterB
                : goalsPerStarterB - goalsPerStarterA;
        });
        team.current_players = sortedArray; // Update the current_players array
        setGoalsPerStarterSort(goalsPerStarterSort === "asc" ? "desc" : "asc");
    }
    // Add other sorting logic for other columns here if needed
};

      
      

  return (
   <>
            {teamLoading ? (
                <Loading />
            ) : team && (
                <>
                <div className="content">
                    <div className="header">
                        <div className="info">
                            <center>
                            <br />
                        <b>Country</b><br />{team.league && team.league.country.name}
                        <br />
                        <br />
                        <br />
                        <b>League</b><br /><Link to={team.league && `/league/${team.league.api_id}`}>{team.league && team.league.name}</Link>
                        </center>
                        </div>
                    </div>
                    <div className="header">
                        <img className="header-logo" src={team.logo_url} alt={team.name} />
                        <center><b>{team.name}</b></center>
                    </div>
                    <div className="header">
                        <div className="info">
                            <center>
                        <b>Coach</b><br /><Link to={team.current_coach && `/coach/${team.current_coach.id}`}>{team.current_coach && team.current_coach.name}</Link>
                        <br />
                        <b>Win</b><br />T{(team.win_percent * 100).toFixed(0)}%  H{(team.win_percent_home * 100).toFixed(0)}% A{(team.win_percent_away *100).toFixed(0)}%
                        <br />
                        <b>Lose</b><br />T{(team.lose_percent * 100).toFixed(0)}%  H{(team.lose_percent_home * 100).toFixed(0)}% A{(team.lose_percent_away *100).toFixed(0)}%
                        <br />
                        </center>
                        </div>
                    </div>
                </div>
                <br />
                <br />
                <div className="container">
                <div className="tabs-container">
                    <div onClick={()=>setTab("Fixtures")} className={`tab ${tab === "Fixtures" ? "active" : ""} `}>Fixtures</div>
                    <div onClick={()=>setTab("Players")} className={`tab ${tab === "Players" ? "active" : ""} `}>Players</div>
                    <div onClick={()=>setTab("Stats")} className={`tab ${tab === "Stats" ? "active" : ""} `}>Stats</div>
                </div>
                    {tab === "Players" &&
                    <div className="table">
                        <div className="table-header">
                            <div className="cell">Position</div>
                            <div className="md-cell">Name</div>
                            <div className="cell">Starter</div>
                            <div className="cell">Substitute</div>
                            <div className="cell">Out</div>
                            <div className="cell">Starting Ratio</div>
                            <div className="cell">Goals</div>
                            <div className="cell">Penalties</div>
                            <div className="cell">Assists</div>
                            <div className="cell sortable" onClick={() => handleSort("goalsPerStarter")}>Goals per starter</div>
                        </div>
                        <div className="table-content">
                        {!teamLoading &&
                            team && team.current_players.length > 0 &&
                            team.current_players.map((player) => (
                                (statProcess(player, "Starter") + statProcess(player, "Substitute") > 0) && (
                                <div className='line'>
                                <div className="cell">{player.position}</div>
                                <Link to={`/player/${player.id}`}>
                                <div className="md-cell">{player.name}</div>
                                </Link>
                                <div className="cell">{statProcess(player, "Starter")}</div>
                                <div className="cell">{statProcess(player, "Substitute")}</div>
                                <div className="cell">{totalGames() - statProcess(player, "Starter") - statProcess(player, "Substitute")}</div>
                                <div className="cell">{((statProcess(player, "Starter") / totalGames()) * 100).toFixed(0)}%</div>
                                <div className="cell">{statProcess(player, "Goals")}</div>
                                <div className="cell">{statProcess(player, "Penalties Scored")}</div>
                                <div className="cell">{statProcess(player, "Assists")}</div>
                                <div className="cell">{statProcess(player, "Goals") === 0 ? 0 : (statProcess(player, "Goals") / statProcess(player, "Starter")).toFixed(2)}</div>
                                </div>)
                            ))
                        }
                        </div>
                    </div>}
                    {tab==="Fixtures" && 
                    <>
                    {team && (
                      <div className="content">
                        <div className="container">
                        <div className="buttons">
                          <div className={`button ${homeAway === "HOME" && 'active'}`} onClick={(e) => {homeAway === "HOME" ? setHomeAway(null) : setHomeAway("HOME")}}>HOME</div>
                          <div className={`button ${homeAway === "AWAY" && 'active'}`}  onClick={(e) => {homeAway === "AWAY" ? setHomeAway(null) : setHomeAway("AWAY")}}>AWAY</div>
                        </div>
                        {team && !teamLoading && !teamError && <Table tableHeader={gamesTableHeader} tableContent={gamesTableContent} />}
                        {teamLoading && <Loading />}
                        {teamError && <>{teamError} - Please contact Florent</>}
                        </div>
                      </div>
                    )}
                  </>
                    
                    }
                    </div>
                </>
                )
            }
    </>
  )
}

export default Team;