import useFetch from "../api/useFetch";
import Loading from "./Loading";
import { useState, useEffect } from "react";
import Table from "./Table";

const Teams = () => {

    const { result: teams, isLoading: teamsLoading, error: teamsError } = useFetch('/teams',[]);
    const [homeAway, setHomeAway] = useState(null)
    const [selectedLeague, setSelectedLeague] = useState(null);
    const [teamsAwayTableContent, setTeamsAwayTableContent] = useState(null);
      // Initialize state for sorting
 
  const teamsTableHeader = [
    { content: "Team", size: "md", isLink: true, link: 'team' },
    { content: "League", size: "md", isLink: true, link: 'league' },
    { content: "Nb", size: "sm", isLink: false },
    { content: "Win", size: "sm", isLink: false },
    { content: "! Win", size: "sm", isLink: false },
    { content: "Lose", size: "sm", isLink: false },
    { content: "! Lose", size: "sm", isLink: false },
    { content: "G F", size: "sm", isLink: false },
    { content: "G A", size: "sm", isLink: false },
    { content: "G F+A", size: "sm", isLink: false },
    { content: "+ 2.5", size: "sm", isLink: false },
    { content: "CS F", size: "sm", isLink: false },
    { content: "CS A", size: "sm", isLink: false },
    { content: "BTTS", size: "sm", isLink: false },
    { content: "UNXF", size: "sm", isLink: false, sort: 'normal' },
  ]

  useEffect(() => {
    if (teams !== null) {
    setTeamsAwayTableContent(
        teams.items.filter(team => team.league !== null).filter(team => selectedLeague === null || team.league.id === selectedLeague.id).map((team) => 
            [[team.name,team.id],
            [team.league.name,team.league.api_id],
            homeAway === "AWAY" ? parseInt(team.away_count) : homeAway === "HOME" ? parseInt(team.home_count) : parseInt(team.count),
            homeAway === "AWAY" ? team.away_win : homeAway === "HOME" ? team.home_win : team.win,
            homeAway === "AWAY" ? team.away_not_win : homeAway === "HOME" ? team.home_not_win : team.not_win,
            homeAway === "AWAY" ? team.away_lose : homeAway === "HOME" ? team.home_lose : team.lose,
            homeAway === "AWAY" ? team.away_not_lose : homeAway === "HOME" ? team.home_not_lose : team.not_lose,
            homeAway === "AWAY" ? parseFloat(team.away_goals_for).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_for).toFixed(1) : parseFloat(team.goals_for).toFixed(1),
            homeAway === "AWAY" ? parseFloat(team.away_goals_against).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_against).toFixed(1) : parseFloat(team.goals_against).toFixed(1),
            homeAway === "AWAY" ? parseFloat(team.away_goals_total).toFixed(1) : homeAway === "HOME" ? parseFloat(team.home_goals_total).toFixed(1) : parseFloat(team.goals_total).toFixed(1),
            homeAway === "AWAY" ? team.away_over_2_5_goals : homeAway === "HOME" ? team.home_over_2_5_goals : team.over_2_5_goals,
            homeAway === "AWAY" ? team.away_cs_for : homeAway === "HOME" ? team.home_cs_for : team.cs_for,
            homeAway === "AWAY" ? team.away_cs_against : homeAway === "HOME" ? team.home_cs_against : team.cs_against,
            homeAway === "AWAY" ? team.away_btts : homeAway === "HOME" ? team.home_btts : team.btts,
            homeAway === "AWAY" ? team.away_unexpected_factor : homeAway === "HOME" ? team.home_unexpected_factor : team.unexpected_factor,
        ])
    );
    }}, [teams, selectedLeague, homeAway]);
    // const [goalsPerStarterSort, setGoalsPerStarterSort] = useState(null);
  return (
    <div className="content">
        <div className="container">
        <div className="league-choice">
            {teams && teams.leagues.map((league) => {return (
              <img src={league.logo_url} alt={league.name} onClick={() => {selectedLeague === league ? setSelectedLeague(null) : setSelectedLeague(league)}} className={selectedLeague === league && 'active'} />
            )})}
            </div>
            <div className="buttons">
                <div className={`button ${homeAway === "HOME" && 'active'}`} onClick={(e) => {homeAway === "HOME" ? setHomeAway(null) : setHomeAway("HOME")}}>HOME</div>
                <div className={`button ${homeAway === "AWAY" && 'active'}`}  onClick={(e) => {homeAway === "AWAY" ? setHomeAway(null) : setHomeAway("AWAY")}}>AWAY</div>
            </div>
            {<>
              {teams && !teamsLoading && !teamsError && <Table tableHeader={teamsTableHeader} tableContent={teamsAwayTableContent} />}
              {teamsLoading && <Loading />}
              {teamsError && <>{teamsError} - Please contact Florent</>}
            </>}


        </div>
    </div>
  )
}

export default Teams